import React, { useEffect } from 'react';

const GiveawayPage: React.FC<any> = () => {
  // redirect
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace(`http://facebook.com/events/1656111074539876/`);
    }
  }, []);

  return <></>;
};

export default GiveawayPage;
